export const baseURL = "https://admin.caretube.care/";
// export const baseURL = "http://192.168.29.183:5000/";

export const secretKey = "bCM$TPCAW|2wbFVTE+&Ji3699M0(4+";

export const projectName = "CareTube";
export const bucketName = "caretube";
export const hostname = "https://blr1.digitaloceanspaces.com/";
export const aws_access_key_id = "DO00TGG6LX7WD46HDAV8";
export const aws_secret_access_key =
  "LirgR7c8cavZoDSSrtYg4MYXxVTeFU0T3g6UYmyzevk";
