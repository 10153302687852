import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../store/dialogue/dialogue.type";
import { addSubAdmin, updateSubAdmin } from "../store/subAdmin/action";
import { Box, Modal, Typography } from "@mui/material";
import Input from "../extra/Input";
import Button from "../extra/Button";
import Multiselect from "multiselect-react-dropdown";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "1px solid #C9C9C9",
  boxShadow: 24,
  p: "19px",
};
const SubAdminAdd = () => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const [subAdmin, setSubAdmin] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [paymentGateway, setPaymentGateway] = useState([]);
  const [role, setRole] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const dispatch = useDispatch();

  const [errors, setError] = useState({
    email: "",
    password: "",
    role: "",
    name: "",
  });

  useEffect(() => {
    setSubAdmin(dialogue);
  }, [dialogue]);

  console.log("dialogueData", dialogueData);

  useEffect(() => {
    if (dialogueData) {
      const data = dialogueData?.roles?.map((data) => {
        return {
          name: data,
        };
      });
      setEmail(dialogueData.email);
      setPassword(dialogueData.password);
      setSelectedValue(dialogueData.roles);
      setPaymentGateway(dialogueData.roles);
      setName(dialogueData?.name);
    }
  }, [dialogueData]);

  useEffect(
    () => () => {
      setError({
        email: "",
        password: "",
        role: "",
        name: "",
      });
      setEmail("");
      setPassword("");
      setRole("");
      setName("");
    },
    [open]
  );

  const userRole = [
    {
      name: "User",
      path: "/admin/userTable",
      icon: "user",
    },
    { name: "Channel", path: "/admin/channel", icon: "channel" },
    { name: "Category", path: "/admin/videoCategories", icon: "category" },
    { name: "Videos", path: "/admin/videos", icon: "videos" },
    { name: "Shorts", path: "/admin/shorts", icon: "shorts" },
    { name: "Report", path: "/admin/allreport", icon: "report" },
    { name: "Sound", path: "/admin/sound", icon: "sound" },
    {
      name: "Premium Plan",
      path: "/admin/premiumPlanTable",
      icon: "premiumPlan",
    },
    { name: "FAQ", path: "/admin/allfaq", icon: "faq" },
    { name: "Contact Us", path: "/admin/contactUs", icon: "contactUs" },
    { name: "Setting", path: "/admin/settingPage", icon: "settingPage" },
  ];

  const onSelect = (selectedList, selectedItem) => {
    setPaymentGateway([...selectedList]);
  };

  const onRemove = (selectedList, removedItem) => {
    setPaymentGateway([...selectedList]);
  };

  const closePopup = () => {
    dispatch({
      type: CLOSE_DIALOGUE,
      payload: {
        dialogue: false,
      },
    });

    let dialogueData_ = {
      dialogue: false,
    };
    sessionStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
    close;
  };

  const handleSubmit = () => {
    if (!email || !password || paymentGateway?.length <= 0 || !name) {
      let error = {};
      if (!name) error.name = "Name is required !";
      if (!email) error.email = "Email Is Required !";
      if (!password) error.password = "Password Is Required !";
      if (paymentGateway?.length <= 0) error.role = "Role Is Required";
      return setError({ ...error });
    } else {
      const data = {
        email,
        password: password,
        roles: paymentGateway,
        name: name,
      };

      console.log("data", data);

      if (dialogueData) {
        // Send the full updated object when updating
        const updateData = {
          ...dialogueData,
          ...data,
        };
        dispatch(updateSubAdmin(updateData, dialogueData._id));
      } else {
        dispatch(addSubAdmin(data));
      }

      closePopup();
    }
  };
  return (
    <>
      <Modal
        open={subAdmin}
        onClose={closePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="create-channel-model">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Sub Admin
          </Typography>
          <form>
            <div className="row mb-2">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <Input
                  label={"Name"}
                  name={"name"}
                  type={"text"}
                  placeholder={"Enter name.."}
                  errorMessage={errors.name && errors.name}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);

                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        name: "name can't be a blank!",
                      });
                    } else {
                      return setError({
                        ...errors,
                        name: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <Input
                  label={"Email"}
                  name={"email"}
                  type={"text"}
                  placeholder={"Email"}
                  errorMessage={errors.email && errors.email}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);

                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        email: "email can't be a blank!",
                      });
                    } else {
                      return setError({
                        ...errors,
                        email: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 subadmin" style={{ position: "relative" }}>
                <Input
                  label={"Password"}
                  name={"pass"}
                  type={"password"}
                  placeholder={"Password"}
                  errorMessage={errors.password && errors.password}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);

                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        password: "password can't be a blank!",
                      });
                    } else {
                      return setError({
                        ...errors,
                        password: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12">
                <label for="googlePlayEmail" className="form-label">
                  Roles
                </label>

                <Multiselect
                  options={userRole}
                  selectedValues={selectedValue}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                />
                {errors.role && (
                  <div className="ml-2 mt-1">
                    {errors.role && (
                      <div className="pl-1 text__left">
                        <span className="text-red">{errors.role}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-end">
              <Button
                onClick={closePopup}
                btnName={"Close"}
                newClass={"close-model-btn"}
              />
              <Button
                onClick={handleSubmit}
                btnName={"Submit"}
                type={"button"}
                newClass={"submit-btn"}
                style={{
                  borderRadius: "0.5rem",
                  width: "88px",
                  marginLeft: "10px",
                }}
              />
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default SubAdminAdd;
